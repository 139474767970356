<template>
  <v-app>

    <v-main style="background-color:#F5F5F5">

      <router-view/> 
    </v-main>
  </v-app>
</template>


<script>
import Vue from 'vue'
import "bootstrap/dist/css/bootstrap.css"
import {fetchactiveSets,fetchactiveAdmissionSet} from '@Appmart/utility'

export default {
  name: 'App',

  data: () => ({
    //
  }),
  async mounted(){

const active = await fetchactiveSets().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})
Vue.prototype.$activeSetting = active.data;



// //// Fetch active admission settings

// const activeAdmission = await fetchactiveAdmissionSet().then(activeSet => {
//   return activeSet;
// }).catch(e => {
//   console.log(e);
//   alert('Something went wrong')
//   next(false)
// })
// Vue.prototype.$activeAdmission = activeAdmission.data;
  }
};
</script>

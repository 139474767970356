<template>
<div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Settings</span>/<span style="padding:5px; color:#555">Result Upload</span>
    </div>
  </div>

  <v-dialog v-model="dialogRegset" width="400">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
        Send for Approval
        <v-spacer></v-spacer>
        <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>

        <v-row>
          <div class="col-md-12 pa-8">
            <p>
              <strong>Regnumber :{{ item.reg }}</strong>
            </p>
            <p>
              <strong>Student Name :{{ item.name }}</strong>
            </p>
            <p>
              <strong>Semester :{{ item.semester }}</strong>
            </p>
            <p>
              <strong>CA :{{ item.ca }}</strong>
            </p>
            <p>
              <strong>Score :{{ item.score }}</strong>
            </p>
            <p>
              <strong>Total :{{ item.total }}</strong>
            </p>
            <p>
              <strong>Grade :{{ item.grade }}</strong>
            </p>
          </div>

          <div class="col-md-12 ">
            <!-- <img style="height: 150px; width: 150px" src="../../../assets/caution.gif" alt=""> <br> -->
            <strong>
              Proceed to sending for approval.
            </strong>
          </div>

        </v-row>
        <v-card-actions>

          <v-btn color="#c0e7fe" @click="openOtpPublish" class="col-md-5">Proceed</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogRegsetT" width="1100">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
        Request for approval confirmation
        <v-spacer></v-spacer>
        <v-icon @click="dialogRegsetT=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-alert type="warning">
You are about to send the result(s) listed bellow to your HOD for approval. Confirm your selection and proceed.<br><strong>Note that for you to edit this result after this action, your HOD will have to reject the approval request. </strong> 
        </v-alert>
        <div>
          <v-data-table :headers="headersConfirm" :items="payload">
            <template v-slot:item.stat="{ item }">
              <span>{{ item.stat == '1' ? 'Awaiting upload' : item.stat == '2' ? 'Awaiting HOD Approval' : 'Awaiting Dean Approval' }}</span>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>

          <v-btn color="#c0e7fe" @click="openOtpPublish" class="m-auto btn-import">Confirm and Proceed</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogImport" persistent width="600">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
        Import Results
        <v-spacer></v-spacer>
        <v-icon @click="dialogImport=false;finput='';preparing=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <div class="pl-6 pr-6 pt-2">
        <v-alert type="info" class="mb-6">
Select the result data file you want to import. File must be in .xlsx. Click here to download the result sheet template. The result sheet template will also contain the list of students that also registerd for {{ course.name }}
</v-alert>
<v-row
        class=""
        align-content="center"
        justify="center" v-if="preparing"
      >
<v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Preparing file for upload
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
</v-row>
        <v-file-input class="mt-2" outlined show-size required id="fileInput" dense MouseEvent @change="uploadcsv" label="Select upload file" v-model="finput" v-if="!preparing"></v-file-input>
      </div>
      <v-card-actions>

        <v-btn color="#c0e7fe" @click="openImportData" :disabled="!finput || preparing" class="m-auto btn-import mb-2">Import result data</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogData" persistent max-width="1100">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
        Import result data
        <v-spacer></v-spacer>
        <v-icon @click="dialogData=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <div class="pa-6" >
        <div class="mb-4" v-if="importBadData.length>0">
         
          <v-alert type="error">
            <h6>Bad Data<br></h6>  There are records in your upload file that does not meet the upload parameter standards. These records will not be imported
          </v-alert>
          <v-data-table :headers="headersPreviwe" :items="importBadData">

          </v-data-table>
        </div>
        <div v-if="importGoodData.length>0">
          <v-alert type="success">
          <h6>Good Data</h6></v-alert>
          <v-data-table :headers="headersPreviwe" :items="importGoodData">
          </v-data-table>
        </div>

      </div>
      <v-card-actions>
        <div></div>
<v-spaer></v-spaer>
        <v-btn color="#c0e7fe" @click="importData" :loading="loading" :disabled="importGoodData.lenght == 0" class="btn-import">Import data</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showOtp" persistent max-width="400">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
         Authorization
        <v-spacer></v-spacer>
        <v-icon @click="showOtp=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <div class="pt-6 pl-6 pr-6">

        <v-text-field type="text" v-model="otp" outlined prepend-inner-icon="mdi-key" label="Daily Otp"  dense :disabled="loading"></v-text-field>
      </div>
      <v-card-actions>

        <v-btn v-if="showbtnSave" color="#c0e7fe" @click="saveChanges" :loading="loading" :disabled="importGoodData.lenght == 0" class="btn-import m-auto">Proceed</v-btn>

        <v-btn v-else color="#c0e7fe" @click="publish" :loading="loading" :disabled="importGoodData.lenght == 0 && !this.otp || this.otp==''|| loading" class="m-auto">Send For Approval</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showbouncedData" max-width="1100" persistent>
    <v-card>
      <v-card-title style="color:#666; font-size:18px;">
        Unprocessed data
        <v-spacer></v-spacer>
        <v-icon @click="showbouncedData=false">mdi-close-circle</v-icon>
      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <div class="pa-6">
        <v-alert type="error" style="color:#fff;font-weight:400">
          <span v-if="fimportBadData>1">These</span><span v-else>This</span> data represented here was not  processed because of the reason given in the reason column.<br>
 <strong>Note:<br>
  - If the reason is "This Student did not register for this course", we stored the data without processing it pending when the student registers the course. For any other reason, the data was stored for record purposes only. No action will be taken afterwards.<br><br>
  - The only results you will see to send for approval are only the results that were processed.<br><br>
  - To see your uploads that were not processed click on the unprocessed uploads on the main result display page.</strong>

        </v-alert>
        <div class="mb-4">
          <v-data-table :headers="fheaders" :items="fimportBadData">

          </v-data-table>
        </div>

      </div>
      <v-card-actions>

        <v-btn color="#c0e7fe" @click="closeUnprocessed" :loading="loading" :disabled="importGoodData.lenght == 0" class="col-md-1 m-auto">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

    <div class=" mb-8">
      <v-card v-if="showform" style="margin: 0 auto" class="pa-12" width="700">
        <div>
          <div class="mb-2"></div>
          <v-autocomplete dense :items="degrees" v-model="degree" outlined label="Select Degree"></v-autocomplete>

          <div class="mb-2"></div>
          <v-autocomplete dense :items="sessions" v-model="session" outlined label="Select Session"></v-autocomplete>

          <div class="mb-2"></div>
          <v-autocomplete dense :items="semesters" v-model="semester" outlined label="Select Semester"></v-autocomplete>

          <div class="mb-2"></div>
          <v-autocomplete dense :items="courses" v-model="course" outlined label="Select Course" ></v-autocomplete>
          
          <v-btn color="#c0e7fe" block  @click="fetchUploadedResults" class="btn-import m-auto" v-if="!loading">Next</v-btn>
          <v-btn color="primary"  elevation='2' class="col-md-12 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Processing Data</v-btn>
          <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
        </div>
      </v-card>

      <v-card v-else>

        <v-row>

          <v-col md="12">
            <v-card  elevation="0">
              <v-alert type='info' class="ma-4" style="font-size:20px !important; font-weight:600" v-if="session>=2022">
Note: starting from 2022/2023 academic session you will not be able to upload result for students that did not register for the course you are about to upload. 
              </v-alert>
              <v-card-title>
                <div style="color:#555;letter-spacing:1">
                Uploading Result for {{ session }} {{ semester }} <span v-if="semester == 1">st </span><span v-else>nd </span> &nbsp;Semester Result for {{ course.name}}
              </div>

                 <v-toolbar class="mb-2" flat >
                <!-- <v-toolbar-title v-if="goodData.length > 0">{{goodData.length}} Data found in the result sheet</v-toolbar-title> -->
                <v-spacer></v-spacer>
                
                
                <a href="https://appcentral.unizik.edu.ng/templates/resultTemplate.xlsx"> <v-btn size="small" class="btn-import mr-2" small>Download Empty Result Template</v-btn></a>

                <vue-excel-xlsx
        :data="csvData3"
        :columns="columns"
        :file-name="session+'_'+course.name+'_resultsheet'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'" v-if="goodData.length>0"
        >
        
        <v-btn color="#c0e7fe" class="btn-import" small >Download Result Sheet</v-btn>
    </vue-excel-xlsx>

                
                <v-btn @click="dialogImport = true" color="#c0e7fe" class="btn-import ml-2" small>Import Result</v-btn>
              </v-toolbar>
              </v-card-title>
              <v-divider style="margin:0;padding:0"></v-divider>
              <v-toolbar class="mb-2 pt-3" flat >
                <div style="color:#555;letter-spacing:1" class="col-md-7">
                  <span v-if="goodData.length>0">{{goodData.length}} Data found in the result sheet</span>  <span v-if="goodData.length==0">No Data found in the result sheet</span> <span class="filterApplied" v-if="mainFilter"> * filter applied </span>
              </div>
              <v-spacer></v-spacer>
                <div class="col-md-2">  <v-autocomplete :clearable="clearable" class="mt-3"  label="Department filter" outlined dense small :items='availableDepartments' v-model="departmentUsed"></v-autocomplete>
</div>
<div class="col-md-2">
  <v-autocomplete :clearable="clearable" class="mt-3"  label="Result status" :items="statuses" v-model="statusFilter" outlined dense></v-autocomplete>
</div>
<div class="col-md-2" style="margin-top:-15px">
  <v-btn color="#c0e7fe" class="btn-import" small @click="applyFilters()">Apply Filter</v-btn>
</div>

                <!-- <v-toolbar-title v-if="goodData.length > 0">{{goodData.length}} Data found in the result sheet</v-toolbar-title> -->
                <v-spacer></v-spacer>
                
                
               
              </v-toolbar>
              <v-divider style="margin:0;padding:0"></v-divider>

              <v-data-table item-key="reg" v-model="selected" :single-select="false" show-select :headers="headers" :items="goodData" items-per-page="200">

                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                  <v-simple-checkbox  :value="isSelected" :readonly="!item.status" :disabled="!item.status" @input="select($event)"></v-simple-checkbox>
                </template>

                <template v-slot:item.ca="{ item }">
                  <input  ref="num" @input="updatetotal(item,'ca')" :disabled="!item.status" class="input-f" type="number" v-model="item.ca">

                </template>
                <template v-slot:item.score="{ item }">
                  <input  ref="num" @input="updatetotal(item,'ex')" :disabled="!item.status" class="input-f" type="number" v-model="item.score">

                </template>
                <template v-slot:item.total="{ item }">
                  <input  :disabled="true" class="input-f" type="number" v-model="item.total">

                </template>
                <template v-slot:item.grade="{ item }">
                  <input  :disabled="!item.status" class="input-f" v-model="item.grade">

                </template>
                <template v-slot:item.stat="{ item }">
                  <span >{{ item.stat == '1' ? 'Preparing Result' : item.stat == '2' ? 'Awaiting HOD Approval' : item.stat == '3' ? 'Awaiting Dean Approval' : item.stat == '4' ? 'Approved' : ''  }}</span>
                </template>
                <!-- <template v-slot:item.action="{ item }">

                  <button v-if="item.status && !item.rejectedReason" class="btn-publish" @click="publishSingle(item)">Send for Approval</button>

                  <div v-if="item.rejectedReason" style="display: flex">
                    <button class="btn-publish" @click="publishSingle(item)" style="font-size: 12px "> Resend </button>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon style="" v-bind="attrs" v-on="on">mdi-eye</v-icon>
                      </template>
                      <span>{{ item.rejectedReason }}</span>
                    </v-tooltip>
                  </div>

                </template> -->
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <div class="ml-5 pb-5">
          <v-btn style="background: #006794; color: #fff;" color="blue-grey" @click="back" class="btn-import" small>
            Back
          </v-btn>
          <span style="width: 20px; display: inline-block"></span>
          <!-- ="selected.length > 0" -->
          <v-btn @click="publishBulk" class="btn-import" color="success" small>
            Send for approval
          </v-btn>
          <v-btn class="ml-3 btn-import" @click="openOtpSave" color="info" small >
            Save
          </v-btn> 
        </div>
      </v-card>
    </div>
  </div>
</div>
</template>

<script>
import slug from 'slug';
import Swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';
import axios from "axios"
import { degree, allSession } from "@Appmart/utility";

export default {
  data() {
    return {
      sessoin:"2020",
      coursesallocated:false,
      preparing:false,
      showform: true,
      mainFilter:false,
      loading:false,
      statuses:[{text:'All', value:'All'},{text:'Preparing Result', value:'1'},{text:'Awaiting HOD Approval', value:'2'},{text:'Awaiting Deans Approval', value:'3'},{text:'Published', value:'3'}],
      statusFilter:'All',
      availableDepartments:[],
      departmentUsed:'All',
      columns : [
                    {
                        label: "Reg",
                        field: "Reg",
                    },
                    {
                        label: "Name",
                        field: "Name",
                    },
                    {
                        label: "Course",
                        field: "Course",
                    },
                    {
                        label: "Semester",
                        field: "Semester",
                    },
                    {
                        label: "Session",
                        field: "Session",
                    },
                    {
                        label: "CA",
                        field: "CA",
                    },
                    {
                        label: "Exam",
                        field: "Exam",
                    }
                    ,
                    {
                        label: "Total",
                        field: "Total",
                    }
                    ,
                    {
                        label: "Grade",
                        field: "Grade",
                    }
                ],
      dialogRegset: false,
      actionModal: false,
      dialogImport: false,
      showOtp: false,
      loading: false,
      dialogData: false,
      finput:'',
      showbtnSave: true,
      dialogRegsetT: false,
      showbouncedData: false,
      semesters:[{text:"1st Semester", value:1},{text:"2nd Semester", value:2}],
      semester:'',
      actionType: 'upload',
      studentRegs: [],
      degree: '',
      otp: '',
      degrees: [],
      course: '',
      courses: [],
      results: [],
      selected: [],
      fheaders: [{
          text: 'Reg Number',
          value: 'reg'
        },

        {
          text: 'Reason',
          value: 'reason'
        },
        {
          text: 'CA',
          value: 'ca'
        },
        {
          text: 'Exam',
          value: 'score'
        },

        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Grade',
          value: 'grade'
        },
      ],
      fimportBadData: [],
      headersPreviwe: [
        //"Reg Number
        //"Student Name	Semester Session	CA	Score	Total	Grade	Degree	
        {
          text: 'Reg Number',
          value: 'Reg'
        },
        {
          text: 'Course',
          value: 'Course'
        },
        {
          text: 'Semester',
          value: 'Semester'
        },
        {
          text: 'Session',
          value: 'Session'
        },
        {
          text: 'CA',
          value: 'CA'
        },
        {
          text: 'Exam score',
          value: 'Exam'
        },

        {
          text: 'Total',
          value: 'Total'
        },
        {
          text: 'Grade',
          value: 'Grade'
        },

      ],
      headersConfirm:[
      {
          text: 'Reg Number',
          value: 'reg'
        },
        {
          text: 'Student Name',
          value: 'name'
        },
        {
          text: 'Course',
          value: 'course'
        },
        {
          text: 'Semester',
          value: 'semester'
        },
        {
          text: 'Session',
          value: 'session'
        },
        {
          text: 'CA',
          value: 'ca'
        },
        {
          text: 'Exam',
          value: 'score'
        },

        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Grade',
          value: 'grade'
        },
       
      ],
      headers: [
        //"Reg Number
        //"Student Name	Semester Session	CA	Score	Total	Grade	Degree	
        {
          text: 'Reg Number',
          value: 'reg'
        },
        {
          text: 'Student Name',
          value: 'name'
        },
        {
          text: 'Course',
          value: 'course'
        },
        {
          text: 'Semester',
          value: 'semester'
        },
        {
          text: 'Session',
          value: 'session'
        },
        {
          text: 'CA',
          value: 'ca'
        },
        {
          text: 'Exam',
          value: 'score'
        },

        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Grade',
          value: 'grade'
        },
        {
          text: 'Status',
          value: 'stat'
        },
        {
          text: 'Uploaded On',
          value: 'upl'
        },
        {
          text: 'Last Updated',
          value: 'lasup'
        },

        {
          text: '',
          value: 'action'
        }
      ],
      item: {},
      datas: [],
      goodData: [],
      goodDataconstatnt:[],
      csvData3:[],
      badData: [],
      sessions: [{text:"2023/2024", value:"2023"},{text:"2022/2023", value:"2022"},{text:"2021/2022", value:"2021"},{text:"2020/2021", value:"2020"},],
      session: '',
      payload: [],
      importGoodData: [],
      importBadData: []
    }
  },
  watch() {

  },
 
  mounted() {
    this.fetchCourses();
    this.fetchDegree();
    // this.fetchSessions();
  },
  methods: {

    /****
     * Applying filter toe the Good data
     */
    applyFilters(){

      //// if there are selected items before clicking on filter. warn and then clear them
      if(this.selected.length > 0) {
alert('You have selected items that have not been used. if you continue, the selection will be lost')
        this.selected = [];
      }
     this.goodData =[]
/// fisrt deselect every selection that is made on the table


//// remove the table and show action for the application,


//// if the there is no filter, just return the result data
if(this.statusFilter =='All' && this.departmentUsed =='All'){
  this.mainFilter = false
this.goodData = this.goodDataconstatnt;

}else{
// If there is any filter selected.. then map and
this.goodData = this.goodDataconstatnt.filter(item =>{
  if(this.departmentUsed != 'All' && this.statusFilter == 'All' ){
    return item.department.name == this.departmentUsed
  }

  if(this.departmentUsed != 'All' && this.statusFilter != 'All' ){
    return item.department.name == this.departmentUsed && item.stat == this.statusFilter
  }
  
  if(this.departmentUsed == 'All' && this.statusFilter != 'All' ){
    return item.stat == this.statusFilter
  }
})
this.mainFilter = true
console.log(this.goodData);
}
    },


    csvExport(arrData) {
                if (arrData.length == 0) {
                    Swal.fire({
                        icon: "info",
                        text: "Table Is Empty",
                        width: 400,
                    });
                } else {
                    let csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += [
                            Object.keys(arrData[0]),
                            ...arrData.map(item => Object.values(item))
                        ]
                        // .trim()
                        .join("\n")
                        .replace(/(^\[)|(\]$)/gm, "");
    
                    const data = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", this.session+'_'+this.course.name+'_'+"resultscheet.csv");
                    link.click();
                }
            },
    openOtpSave() {
      this.otp=''
      this.showOtp = true;
      this.showbtnSave = true;
    },
    openOtpPublish() {
      this.dialogRegsetT = false;
      this.showOtp = true;
      this.showbtnSave = false;
      this.dialogRegset = false;
    },

    //// calculate the c and exam to give the total
    updatetotal(item,type) {
      if (item.score) {
       var total = Number(item.score) + Number(item.ca);
      } else {
       var total = Number(item.ca);
      }
item.total = total <=100?(total):(0)
if(total > 100){
  type == 'ex'?item.score = 0:item.ca = 0
  alert('Total Cannot be more than 100')

}
},
    openuploadType() {
      if (!this.course || !this.degree || !this.session) {
         Swal.fire({
          icon: 'error',
          title: 'Oops..',
          text: 'Plase make sure to complete the fields in order to proceed'
        })
        return
      } else {

        this.actionModal = true;
      }
    },

    closeUnprocessed() {
      this.fimportBadData = [];
      this.showbouncedData = false;
    },

    // updatetotal1(item) {

    //   if (item.ca) {
    //     item.total = Number(item.ca) + Number(item.score);
    //   } else {
    //     item.total = Number(item.score);
    //   }

    // },
   async fetchStudentName(){
    try {
      const studentRegs = this.studentRegs;
      var url = this.$appUrl+'fetch-student-names'
      const res = await axios.post(url, {
           studentRegs
          });
console.log('Student names',res);
          if(res.data.code == 's200'){
            for (let i = 0; i < res.data.message.length; i++) {
              const name = res.data.message[i];
              for (let j = 0; j < this.goodData.length; j++) {
                const el = this.goodData[j];

                if(el.reg == name.regNumber){
                  if(name.name){
                    el['name'] = name.name
                  }else{
                    el['name'] = 'Student Data Not Found';
                    el['ca'] = '';
                    el['score'] = '';
                    el['total'] = '';
                    el['grade']='';
                    el['status'] = false;
                    /*
                    session: this.session,
              ca: result.ca,
              score: result.score,
              total: result.total,
              grade: result.grade,
              degreeId: this.degree.id
                    */
                  }
                }
                
              }
            }
     
          }else{
            return Swal.fire({
              icon:'error',
              text: res.data.message,
              title: 'Oopx..'
            })
          }
    } catch (error) {
      console.log(error);
    }
    },

    async saveChanges() {
      this.loading = true;
      try {

        if (!this.otp) {
          this.loading = false;
          return Swal.fire({
            icon: 'error',
            text: 'Please enter otp',
            title: "Authorization"
          })
        } else {
          const results = this.goodData.map(result => {
            console.log('result', result);
            return {
              courseId: result.courseId,
              department: this.course.department,
              name: result.name,
              reg: result.reg,
              semester: result.semester == 'First' ? 1 : 2,
              session: this.session,
              ca: result.ca,
              score: result.score,
              total: result.total,
              grade: result.grade,
              degreeId: this.degree.id
            }
          });

          var url = this.$appUrl+'save-results'

          const res = await axios.post(url, {
            results,
            uploadedBy: '',
            otp: this.otp,
            program:this.$activeSetting.data.program._id
          },{ withCredentials: true });
          console.log('Hello', res);
          console.log('payload', this.selected)

          if (res.data.code === 's200') {

            this.showOtp = false;
            this.otp = '';
            if (res.data.unloaded) {
              console.log('here');
              res.data.unloaded.forEach(el => {
this.fimportBadData = [];
                this.fimportBadData.push({
                  reason: el.rejectedReason,
                  grade: el.grade,
                  ca: el.ca,
                  total: el.total,
                  score: el.score,
                  reg: el.reg,
                })
              })

              this.showbouncedData = true;

            
            }else{
              Swal.fire({
              icon: 'success',
              text: 'Save successfully',
              title: 'Successful'
            });
            }
            
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.message,
              title: 'Oops..'
            });
          }
          this.loading = false;
        }

      } catch (error) {
        this.loading = false;
        console.log(error);
      }

    },





    publishBulk() {
      this.otp =''
      this.payload = this.selected;
      let proceed = true;

      this.payload.every(el => {
        if (!el.grade || !el.total || !el.score || el.grade == '- -') {
          proceed = false;
          Swal.fire({
            icon: 'error',
            text: `Please make sure to complete the result fields for selected student with regnumber ${el.reg} to proceed`
          })
          return false
        } else {
          return true

        }
        
      })

      if (proceed) {
        this.dialogRegsetT = true;
        console.log(this.selected)
      }

    },


    /***
     * 
     *  PERFORM 
     * 
     */
    importData() {
      this.finput = ''
      const badData = [];
      const uploadData = [];
      console.log(this.importGoodData);
      console.log(this.goodData);


      /// loop through the good data that was inported
      this.importGoodData.forEach(el => {
        console.log(el);

        const result = this.goodData.filter(d => d.reg == el.Reg);
        console.log({result});

        if (result[0]) {
          if (result[0].stat == '1') {
            result[0].score = el.Exam;
            result[0].grade = el.Grade;
            result[0].total = el.Total;
            result[0].ca = el.CA;

          } else {
            badData.push({
              reg: el.Reg,
            ca: el.CA,
            score: el.Exam,
            total: el.Total,
            grade: el.Grade,
              reason: `This result has already been sent for approval`
            });
          }
        } else {
          if(this.session < 2022){
          this.goodData.push({
            reg: el.Reg,
            name: el.Name,
            course: this.course.name,
            semester: el.Semester,
            session: this.session,
            ca: el.CA,
            score: el.Exam,
            total: el.Total,
            grade: el.Grade,

            courseId: this.course.id,
            degree: el.degree,
            upl: '-',
            lasup: '-',
            status: true,
            stat: 1
          })

          this.studentRegs = this.goodData.map(el => el.reg);
          
          this.fetchStudentName();

           } else {
          badData.push({
            reg: el.Reg,
            ca: el.CA,
            score: el.Exam,
            total: el.Total,
            grade: el.Grade,
            reason: `This Student did not register for this course`
          });
        }
        }

       
      })

      if (badData.length !== 0) {
        this.fimportBadData = badData.map(el => {
          return {
            reason: el.reason,
            grade: el.grade,
            ca: el.ca,
            total: el.total,
            score: el.score,
            reg: el.reg
          }
        })
        this.dialogData = false;
        this.showbouncedData = true;
      }

      this.dialogData = false;

    },




    uploadcsv() {
      this.preparing = true
      this.badData = [];
      this.approveList = [];
      this.importGoodData = [];
      this.importBadData = [];

      const input = document.getElementById("fileInput");

      readXlsxFile(input.files[0]).then((rows) => {
        const key = rows[0];
        // console.log('ROWS',rows)"Reg Number
//"	Semester	Session	CA	Exam	Total	Grade
        //"Student Name	Semester Session	CA	Score	Total	Grade	Degree
        if (
          key[0] !== 'Reg' ||
            key[1] !== 'Name' ||
            key[2] !== 'Course' ||
          key[3] !== 'Semester' ||
          key[4] !== 'Session' ||
          key[5] !== 'CA' ||
          key[6] !== 'Exam' ||
          key[7] !== 'Total' ||
          key[8] !== 'Grade' 


        ) {
          console.log("ERROR");
          Swal.fire(
            "Wrong Result Template Format",
            'Download the Result sheet as your upload template',
            "error"
          );
        } else {
          rows.shift(); //Remove the header row
          const goodData = [];
          const badData = [];

          for (const row of rows) {
            if (row.includes(null) && row[1] != null) {
              row["Reg"] = row[0];
              delete row[0];
              row['Course'] = row[2];
              delete row[2];
              row['Semester'] = row[3];
              delete row[3];
              row['Session'] = row[4];
              delete row[4];

              row['CA'] = row[5];
              delete row[5];
              row['Exam'] = row[6];
              delete row[6]
              // row['total'] = row[6];
              // delete row[6];
              row['Grade'] = row[8];
              row['Total'] = Number(row['CA']) + Number(row['Exam']);
              delete row[8];

              row['course'] = this.course.id;
              row['department'] = this.course.department
              row['degree'] = this.degree.name;

              badData.push(row);

            } else {

              if(row[3] !== this.semester){
                row["Reg"] = row[0];
              delete row[0];
              row['Name'] = row[1];
              delete row[1];
              row['Course'] = row[2];
              delete row[2];
              row['Semester'] = row[3];
              delete row[3];
              row['Session'] = row[4];
              delete row[4];

              row['CA'] = row[5];
              delete row[5];
              row['Exam'] = row[6];
              delete row[6]
              // row['total'] = row[6];
              // delete row[6];
              row['Grade'] = row[8];
              row['Total'] = Number(row['CA']) + Number(row['Exam']);

              row['course'] = this.course.name;
              row['department'] = this.course.department
              row['degree'] = this.degree.name;
              row['degreeId'] = this.degree.id;
              row['courseId'] = this.course.id;

              badData.push(row);
              }else{
                row["Reg"] = row[0];
              delete row[0];
              
              row['Course'] = row[2];
              delete row[2];
              row['Semester'] = row[3];
              delete row[3];
              row['Session'] = row[4];
              delete row[4];

              row['CA'] = row[5];
              delete row[5];
              row['Exam'] = row[6];
              delete row[6]
              // row['total'] = row[6];
              // delete row[6];
              row['Grade'] = row[8];
              row['Total'] = Number(row['CA']) + Number(row['Exam']);

              row['course'] = this.course.name;
              row['department'] = this.course.department
              row['degree'] = this.degree.name;
              row['degreeId'] = this.degree.id;
              row['courseId'] = this.course.id;

              goodData.push(row);
              }
            
            }

          }

          this.importGoodData = goodData.map(el => {
            return {
              ...el
            }
          });
          this.importBadData = badData.map(el => {
            return {
              ...el
            }
          });
          // this.results = data;
          // console.log('DAta',this.goodData, this.badData);
        }
        this.preparing = false
      });
/// once preparing
    },

    openImportData() {
      if(this.importBadData.length>0 || this.importGoodData.length>0) {
      this.dialogData = true;
      this.dialogImport = false;
      this.finput = ''
      }else{
        return Swal.fire({
          icon: 'error',
          text: 'Looks like you are trying to upload an empty template'
        })
      }
    },

    publishSingle(item) {
      if (!item.grade || !item.total || !item.score) {
        return Swal.fire({
          icon: 'error',
          text: 'Plase make sure to complete the fields in order to proceed'
        })
      }
      this.item = item;
      this.dialogRegset = true;
      this.payload = [item];
      console.log(item);
    },

    

    async fetchUploadedResults() {
      this.mainFilter=false
      this.departmentUsed='All'
      this.statusFilter='All'
      this.goodData =[];
      this.csvData3=[]
      if (this.degree=='') {
        return Swal.fire({
          icon: 'error',
          text: 'Please select degree'
        })
      }

      if (this.session=='') {
        return Swal.fire({
          icon: 'error',
          text: 'Please select session'
        })
      }

      if (this.semester=='') {
        return Swal.fire({
          icon: 'error',
          text: 'Please select semester'
        })
      }


      if (this.course=='') {
        return Swal.fire({
          icon: 'error',
          text: 'Please select course'
        })
      }

      try {
        this.loading = true;
        const filter = {
          session: this.session,
          course: this.course.id,
          degree: this.degree.id
        }
        var url = this.$appUrl+'/fetch-result';

        const res = await axios.post(url, filter);

        if (res.data.code == 's200') {
          const goodData = []; this.goodDataconstatnt=[]; this;
          res.data.message.forEach(el => {

            goodData.push({
              reg: el.studentRegNo,
              name: el.studentName,
              course: this.course.name,
              semester: el.semester,
              session: this.session,
              ca: el.cA,
              score: el.examScore,
              total: el.total,
              grade: el.grade,
              rejectedReason: el.rejectedReason,
              courseId: this.course.id,
              degree: el.degree,
              upl: el.dateUploaded.split('T')[0],
              lasup: el?.updatedOn ? el.updatedOn.split('T')[0] : '-',
              status: el.examScore && el.grade && el.total && el.cA && el.status >= 2 ? false : true,
              studentId: el.studentId,
              stat: el.status,
              department:el.departmentId

            })
            this.goodDataconstatnt.push({
              reg: el.studentRegNo,
              name: el.studentName,
              course: this.course.name,
              semester: el.semester,
              session: this.session,
              ca: el.cA,
              score: el.examScore,
              total: el.total,
              grade: el.grade,
              rejectedReason: el.rejectedReason,
              courseId: this.course.id,
              degree: el.degree,
              upl: el.dateUploaded.split('T')[0],
              lasup: el?.updatedOn ? el.updatedOn.split('T')[0] : '-',
              status: el.examScore && el.grade && el.total && el.cA && el.status >= 2 ? false : true,
              studentId: el.studentId,
              stat: el.status,
              department:el.departmentId
            }),


            this.csvData3.push({
             Reg: el.studentRegNo,
              Name: el.studentName,
              Course: this.course.name,
              Semester: el.semester,
              Session: this.session,
              CA: el.cA,
              Exam: el.examScore,
              Total: el.total,
              Grade: el.grade
            })

          })
///// filter the data take agrregate people that do not have total tot the top
         this.goodData = goodData.sort((a, b) => b.status - a.status);

//// sort and get all the available departments from the upload
this.availableDepartments = [...new Set(this.goodData.map(item => item.department.name))]; 
this.availableDepartments.unshift('All')

console.log(this.availableDepartments);

          this.showform = false;
          this.loading = false;


        }
        console.log(res);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    back() {
      this.degree = '';
      this.course = '';
      this.goodData = [];
      this.badData = [];
      this.showform = true;
    },
    async fetchCourses() {
      try {
        var url = this.$appUrl+'/get-courses'
        const res = await axios.get(url);
        // console.log('Course', res);
        if (res.data.code == 's200') {
          res.data.message.forEach(course => {
            this.courses.push({
              text: `${course.name} (${course.course_code})`,
              value: {
                id: course._id,
                department: course.offeringDepartment,
                name: course.name
              }
            })

          });
          // this.courseHolder.push(...this.courses);
          // console.log('Profiled Course', res);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async publish() {
      this.loading = true;
     if(!this.otp || this.otp==''){
      Swal.fire({
            icon: 'error',
            text: `Invalid Daily OTP`,
            title: 'Result Approval Request'
          });
          this.loading = false;
     }
      try {

        /// map the results for publishing
        const results = this.payload.map(result => {
          return {
            courseId: result.courseId,
            department: this.course.department,
            name: result.name,
            reg: result.reg,
            semester: result.semester == 'First' ? 1 : 2,
            session: this.session,
            ca: result.ca,
            score: result.score,
            total: result.total,
            grade: result.grade,
            degreeId: this.degree.id,
            studentId:result.studentId

          }
        });

        var url = this.$appUrl+'upload-result'
        const res = await axios.post(url, {
          results,
          otp: this.otp,
          session:this.session
        },{withCredentials: true});

        if (res.data.code === 's200') {

          this.dialogRegset = false;
          this.dialogData = false;
          this.dialogRegsetT = false;
          this.otp = '';
          this.showOtp = false;

          this.payload.forEach(el => {
            const restt = this.goodData.filter(d => d.reg == el.reg);
            restt[0].status = false;
            restt[0].stat = '2';
            restt[0].rejectedReason = '';
            restt[0].upl = new Date().toISOString().split('T')[0];
          })

               // this.payload = this.payload.filter(el => );
               if(res.data.unloaded){

                res.data.unloaded.forEach(el => {
this.fimportBadData = [];
                this.fimportBadData.push({
                  reason: el.rejectedReason,
                  grade: el.grade,
                  ca: el.ca,
                  total: el.total,
                  score: el.score,
                  reg: el.reg,
                })
              })

              this.showbouncedData = true;
            res.data.unloaded.forEach(el => {
                for (let i = 0; i < this.goodData.length; i++) {
                  const cour = this.goodData[i];
                  if(el.reg == cour.reg){
                    cour.stat = '1';
                    cour.rejectedReason = el.rejectedReason;
                    cour.status = true;
                    cour.upl = new Date().toISOString().split('T')[0];
                  }
                }
              })
          }else{
            Swal.fire({
            icon: 'success',
            text: `${this.selected.length} results sent for departmental approval`,
            title: 'Result Approval Request'
          });
          }
          
          this.selected = [];

          // this.showform = true;
          // this.dialogRegset = false;

         // return;

        } else {
          Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Result Approval Request'
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    
    async fetchDegree() {
      try {
        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          console.log(programDegree);
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
    this.loaded = true

          console.log(degree);
        // }
      } catch (error) {
        console.log(error);
      }

    },

  }
}
</script>

<style>
.input-f {
  width: 60px !important;
  display: inline-block;
  background-color: #ccc;
  padding: .4rem;
  border-radius: 3px;

}
.filterApplied{
  color: orangered; font-style: italic; font-size:12px; letter-spacing: 1.5px;
}
.btn-publish {
  font-weight: bolder;
  color: green;
  transition: all ease-in-out .2s;
  margin-right: 15px;
}

.btn-publish:active {
  transform: scale(0.9);
}

.btn-import {
  text-transform: capitalize;
  width: auto;
}
</style>

<template>
  <div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Academics</span>/<span style="padding:5px; color:#555">Faculty Result Moderation</span>
    </div>
  </div>
  
    <v-dialog v-model="dialogRegset" width="400">
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          Send for Approval
          <v-spacer></v-spacer>
          <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <v-card-text>
  
          <v-row>
            <div class="col-md-12 pa-8">
              <p>
                <strong>Regnumber :{{ item.reg }}</strong>
              </p>
              <p>
                <strong>Student Name :{{ item.name }}</strong>
              </p>
              <p>
                <strong>Semester :{{ item.semester }}</strong>
              </p>
              <p>
                <strong>CA :{{ item.ca }}</strong>
              </p>
              <p>
                <strong>Score :{{ item.score }}</strong>
              </p>
              <p>
                <strong>Total :{{ item.total }}</strong>
              </p>
              <p>
                <strong>Grade :{{ item.grade }}</strong>
              </p>
            </div>
            <v-textarea v-if="actionType == '2'" class="mt-5" filled name="input-7-4" label="Enter Reason" v-model="reason"></v-textarea>
            <div class="col-md-12 ">
              <!-- <img style="height: 150px; width: 150px" src="../../../assets/caution.gif" alt=""> <br> -->
              <strong>
                Proceed to sending for approval.
              </strong>
            </div>
  
          </v-row>
          <v-card-actions>
  
            <v-btn color="#c0e7fe" @click="showOtpD2" class="col-md-5">Proceed</v-btn>
          </v-card-actions>
        </v-card-text>
  
      </v-card>
    </v-dialog>
  
    <v-dialog v-model="showOtp" persistent max-width="400">
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          Enter Opt
          <v-spacer></v-spacer>
          <v-icon @click="showOtp=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <div class="pa-6">
  
          <v-text-field type="number" v-model="otp" outlined prepend-inner-icon="mdi-key" label="Enter Otp" single-line dense></v-text-field>
        </div>
        <v-card-actions>
  
          <v-btn v-if="actionType == '1'" color="#c0e7fe" @click="publish" :disabled="selected.lenght == 0" class="">Approve Result</v-btn>
  
          <v-btn v-if="actionType == '2'" color="#c0e7fe" @click="reject" :disabled="selected.lenght == 0" class="">Reject</v-btn>
  
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <v-dialog v-model="dialogRegsetT" width="1100">
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          Send for Approval
          <v-spacer></v-spacer>
          <v-icon @click="dialogRegsetT=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <v-card-text>
          <div>
            <v-data-table :headers="headersi" :items="payload" hide-default-footer>
              <template v-slot:item.stat="{ item }">
                <span>{{ item.stat == '1' ? 'Awaiting upload' : item.stat == '2' ? 'Awaiting your Approval' : item.stat == '3' ? 'Awaiting Dean Approval' : 'Approved' }}</span>
              </template>
  
            </v-data-table>
  
            <v-textarea v-if="actionType == '2'" class="mt-5" filled name="input-7-4" label="Enter Reason" v-model="reason"></v-textarea>
          </div>
          <v-card-actions>
  
            <v-btn color="#c0e7fe" @click="showOtpD" class="" :disabled="payload.length <= 0">Proceed</v-btn>
          </v-card-actions>
        </v-card-text>
  
      </v-card>
    </v-dialog>
  
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
  
      <div class=" mb-8">
        <v-card v-if="showform" style="margin: 0 auto" class="pa-12" width="700">
          <div>
  
            <div class="mb-2"></div>
            <v-autocomplete dense :items="sessions" v-model="session" outlined label="Select Session"></v-autocomplete>
  
            <div class="mb-2"></div>
            <v-autocomplete dense :items="degrees" v-model="degree" outlined label="Select Degree"></v-autocomplete>
  
            <div class="mb-2"></div>
            <v-autocomplete dense :items="courses" v-model="course" outlined label="Select Course"></v-autocomplete>
  
            <v-btn color="#c0e7fe" block @click="initialize" class="ma-auto pa-3" v-if="!loading">Next</v-btn>
            <v-btn color="primary"  elevation='2' class="col-md-12 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Processing Data</v-btn>
            <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
          </div>
        </v-card>
  
        <v-card v-else>
  
          <v-row>
  
            <v-col md="12">
              <v-card  elevation="0">
<v-card-title>
  Moderating {{ course.name }} ({{ course.code }}) for {{ session }} / {{parseInt(session)+1}} Academic Session 
</v-card-title>
                <!-- <v-toolbar class="mb-2" flat>
                  <v-toolbar-title>{{goodData.length}} Data</v-toolbar-title>
                </v-toolbar> -->
                <v-divider style="margin:0;padding:0"></v-divider>
                  <v-toolbar class="mb-2" flat>
                    <v-spacer></v-spacer>
                <div class="col-md-2">  <v-autocomplete :clearable="clearable" class="mt-3"  label="Department filter" outlined dense small :items='availableDepartments' v-model="departmentUsed"></v-autocomplete>
</div>
<div class="col-md-2">
  <v-autocomplete :clearable="clearable" class="mt-3"  label="Result status" :items="statuses" v-model="statusFilter" outlined dense></v-autocomplete>
</div>
<div class="col-md-2" style="margin-top:-15px">
  <v-btn color="#c0e7fe" class="btn-import" small @click="applyFilters()">Apply Filter</v-btn>
</div>

                <!-- <v-toolbar-title v-if="goodData.length > 0">{{goodData.length}} Data found in the result sheet</v-toolbar-title> -->
                  </v-toolbar>
                <div class="row" style="margin:0;padding:0; margin-bottom:15px">
                  <div class="col-md-6"></div>
                  <div class="col-md-2 indicatorkey">Result Summary
                    <v-divider style="margin:0; margin-bottom:10px"></v-divider>
                    <div>
    Total No. Of Results : {{ goodData.length }}
  </div>
  <v-divider style="margin:0; margin-bottom:10px; margin-top:10px"></v-divider>

  <div>
    Grade A : {{ gra.length }} Student(s)
  </div>
  <div>
    Grade B : {{ grb.length }} Student(s)
  </div>
  <div>
    Grade C : {{ grc.length }} Student(s)
  </div>
  <div>
    Grade D : {{ grd.length }} Student(s)
  </div>
  <div>
    Grade E : {{ gre.length }} Student(s)
  </div>
  <div>
    Grade F : {{ grf.length }} Student(s)
  </div>
              
              </div>
                  <div class="col-md-3 indicatorkey">Status Keys
                    <v-divider style="margin:0; margin-bottom:10px"></v-divider>

              <div class=" indicatorkey"><span class="dot-amber"></span> Awaiting dean approval : {{ awaitingDean.length }}</div> <div class=" indicatorkey"><span class="dot-green"></span> Published : {{ published.length }}</div>
              </div>
              
            </div>
            <v-spacer></v-spacer>
            
            
                <v-data-table item-key="reg" v-model="selected" :single-select="false" show-select :headers="headers" :items="goodData">
  
                  <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <v-simple-checkbox v-if="item.status" :value="isSelected" :readonly="!item.status" :disabled="!item.status" @input="select($event)"></v-simple-checkbox>
                  </template>
  
                  <template v-slot:item.stat="{ item }">
                    <div class="indicatorkey" v-if="item.stat == '2'"><span class="dot-red"></span></div>
                    <div class="indicatorkey" v-if="item.stat == '3'"><span class="dot-amber"></span></div>
                    <div class="indicatorkey" v-if="item.stat == '4'"><span class="dot-green"></span></div>

                    <!-- <span>{{ item.stat == '1' ? 'Awaiting upload' : item.stat == '2' ? 'Awaiting your Approval' : item.stat == '3' ? 'Awaiting Dean Approval' : 'Approved' }}</span> -->
                  </template>
  
                  <!-- <template v-slot:item.action="{ item }">
  
    
                      <button v-if="item.status" class="btn-publish" @click="publishSingle(item)">Approve</button>
                    </template> -->
  
                  <template v-slot:item.action="{ item }">
                    <div v-if="item.stat == '3'">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="" text v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="publishSingle(item, '1')">
                            <v-list-item-title>
                              <v-icon class="mr-2" small>
                                mdi-check
                              </v-icon>Approve
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="publishSingle(item, '2')">
                            <v-list-item-title>
                              <v-icon class="mr-2" small>
                                mdi-cancel
                              </v-icon>Reject
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <div class="ml-5 pb-5">
            <v-btn style="background: #006794; color: #fff; height: 50px" color="blue-grey" @click="back">
              Back
            </v-btn>
            <span style="width: 20px; display: inline-block"></span>
            <v-btn @click="publishBulk('1')" :disabled="selected.length == 0" style="background: #D6ECF5; color:#006794; height: 50px">
              Send for Faculty approval
            </v-btn>
            <v-btn class="ml-5" @click="publishBulk('2')" :disabled="selected.length == 0 " style="background: #D6ECF5; color:#006794; height: 50px">
              Reject Result
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  // import readXlsxFile from 'read-excel-file';
  import { degree, allSession } from "@Appmart/utility";

  export default {
    data() {
      return {
        showform: true,
        dialogRegset: false,
        dialogRegsetT: false,
        showOtp: false,
        isPublish: true,
        otp: '',gra:[],grb:[],grc:[],grd:[],gre:[],grf:[],awaitingMe:[],awaitingDean:[],published:[],
        degree: '',
        degrees: [],
        course: '',
        courses: [],
        results: [],
        selected: [],
        availableDepartments:[],
        statuses:[{text:'All', value:'All'},{text:'Awaiting Deans Approval', value:'3'},{text:'Published', value:'4'}],
        loading:false,
        statusFilter:'All',
      departmentUsed:'All',
        headersi: [
          //"Reg Number
          //"Student Name	Semester Session	CA	Score	Total	Grade	Degree	
          {
            text: 'Reg Number',
            value: 'reg'
          },
          {
            text: 'Student Name',
            value: 'name'
          },
          {
            text: 'CA',
            value: 'ca'
          },
          {
            text: 'Exam',
            value: 'score'
          },
  
          {
            text: 'Total',
            value: 'total'
          },
          {
            text: 'Grade',
            value: 'grade'
          }
         
        ],
        headers: [
          //"Reg Number
          //"Student Name	Semester Session	CA	Score	Total	Grade	Degree	
          {
            text: 'Reg',
            value: 'reg'
          },
          {
            text: 'Student',
            value: 'name'
          },
          
          {
            text: 'CA',
            value: 'ca'
          },
          {
            text: 'Exam',
            value: 'score'
          },
  
          {
            text: 'Total',
            value: 'total'
          },
          {
            text: 'Grade',
            value: 'grade'
          },
          {
            text: 'Status',
            value: 'stat'
          },
          ,{
            text: 'Uploaded by',
            value: 'uploadedBy'
          },
          // {
          //   text:'Uploaded On',
          //   value: 'upl'
          // },
          {
            text: 'Queed On',
            value: 'lasup'
          },
          {
            text: '',
            value: 'action'
          }
        ],
        item: {},
        datas: [],
        goodData: [],
        badData: [],
        sessions: [{text:"2020/2021", value:"2020"},{text:"2021/2022", value:"2021"},{text:"2022/2023", value:"2022"},{text:"2023/2024", value:"2023"}],
        session: '',
        reason: '',
        payload: [],
        actionType: null,
        mainFilter:false,
        goodDataconstatnt:[]
      }
    },
    watch() {
  
    },
    beforeMount() {
      this.fetchCourses();
      this.fetchDegree();
      // this.fetchSessions();
    },
    methods: {

    /****
     * Applying filter toe the Good data
     */
     applyFilters(){

//// if there are selected items before clicking on filter. warn and then clear them
if(this.selected.length > 0) {
alert('You have selected items that have not been used. if you continue, the selection will be lost')
  this.selected = [];
}
this.goodData =[]
/// fisrt deselect every selection that is made on the table


//// remove the table and show action for the application,


//// if the there is no filter, just return the result data
if(this.statusFilter =='All' && this.departmentUsed =='All'){
this.mainFilter = false
this.goodData = this.goodDataconstatnt;

}else{
// If there is any filter selected.. then map and
this.goodData = this.goodDataconstatnt.filter(item =>{
if(this.departmentUsed != 'All' && this.statusFilter == 'All' ){
return item.department.name == this.departmentUsed
}

if(this.departmentUsed != 'All' && this.statusFilter != 'All' ){
return item.department.name == this.departmentUsed && item.stat == this.statusFilter
}

if(this.departmentUsed == 'All' && this.statusFilter != 'All' ){
return item.stat == this.statusFilter
}
})
this.mainFilter = true
console.log(this.goodData);
}
},

      rejectResults() {
  
      },
      showOtpD2() {
        if (this.actionType == '2' && !this.reason) {
          return Swal.fire({
            icon: 'error',
            text: 'Reason for rejecting is required',
            title: 'Oops..'
          })
        } else {
          this.showOtp = true;
          this.dialogRegset = false;
          this.isPublish = false;
        }
  
      },
      showOtpD() {
        if (this.actionType == '2' && !this.reason) {
          return Swal.fire({
            icon: 'error',
            text: 'Reason for rejecting is required',
            title: 'Oops..'
          })
        } else {
          this.showOtp = true;
          this.dialogRegsetT = false;
          this.isPublish = true;
  
        }
      },
  
      publishBulk(type) {
        this.reason = '';
        this.actionType = type;
        this.payload = this.selected.filter(e=>{
          return e.stat =='3'
        });
        this.dialogRegsetT = true;
  
      },
      publishSingle(item, type) {
        this.reason = '';
        this.actionType = type;
        this.item = item;
        this.payload = [item];
        this.dialogRegset = true;
      },
      async initialize() {
        this.departmentUsed='All'
      this.statusFilter='All'
      this.goodData =[];
      this.mainFilter=false

        if (!this.degree || !this.course || !this.session) {
          return Swal.fire({
            icon: 'error',
            text: 'Please make sure to fill all fields properly',
            title: 'Oops..'
          })
        } else {
  
          try {
            this.loading= true
            var url = this.$appUrl+`fetch-hod-result-approval`
            const res = await axios.post(url, {
              courseId: this.course.id,
              adminType: 'dean',
              session: this.session,
              program: 'undergraduate_regular',
              degree: this.degree.id
            });
  
            if (res.data.code == 's200') {
              this.showform = false;
              const goodData = []; this.goodDataconstatnt=[];
              res.data.message.forEach(el => {
                goodData.push({
                  reg: el.studentRegNo,
                  name: el.studentName,
                  course: this.course.name,
                  semester: el.semester == 1 ? 'First' : el.semester == 2 ? 'Second' : '',
                  session: this.session,
                  ca: el.cA,
                  score: el.examScore,
                  total: el.total,
                  upl: el?.dateUploaded ? el.dateUploaded.split('T')[0] : '-',
  lasup: el?.sentDate ? el.sentDate.split('T')[0] : '_',
                  grade: el.grade,
                  courseId: this.course.id,
                  degree: el.degree,
                  status: el.status == 3 ? true : false,
                  stat: el.status,
                  department:el.departmentId,
                  id: el._id,
                  uploadedBy:el.uploadedBy
                });

                this.goodDataconstatnt.push({
                  reg: el.studentRegNo,
                  name: el.studentName,
                  course: this.course.name,
                  semester: el.semester == 1 ? 'First' : el.semester == 2 ? 'Second' : '',
                  session: this.session,
                  ca: el.cA,
                  score: el.examScore,
                  total: el.total,
                  upl: el?.dateUploaded ? el.dateUploaded.split('T')[0] : '-',
  lasup: el?.sentDate ? el.sentDate.split('T')[0] : '_',
                  grade: el.grade,
                  courseId: this.course.id,
                  degree: el.degree,
                  status: el.status == 3 ? true : false,
                  stat: el.status,
                  department:el.departmentId,
                  id: el._id,
                  uploadedBy:el.uploadedBy
                });
  
              })
              this.goodData = goodData;

//// sort and get all the available departments from the upload
this.availableDepartments = [...new Set(this.goodData.map(item => item.department.name))]; 
this.availableDepartments.unshift('All')


console.log('thisddddd',this.goodData);

              this.gra = this.goodData.filter(el=>{
                return el.grade == 'A'
              })
              this.grb = this.goodData.filter(el=>{
                return el.grade == 'B'
              })
              this.grc = this.goodData.filter(el=>{
                return el.grade == 'C'
              })
              this.grd = this.goodData.filter(el=>{
                return el.grade == 'D'
              })
              this.gre = this.goodData.filter(el=>{
                return el.grade == 'E'
              })
              this.grf = this.goodData.filter(el=>{
                return el.grade == 'F'
              })
              this.awaitingMe = this.goodData.filter(el=>{
                return el.stat == '3'
              })
              this.awaitingDean = this.goodData.filter(el=>{
                return el.stat == '3'
              })
              this.published = this.goodData.filter(el=>{
                return el.stat == '4'
              })
              this.loading= false

            } else {
              this.loading= false

              Swal.fire({
                icon: 'error',
                text: res.data.message,
                title: 'Oops..'
              })
            }

          } catch (error) {
            this.loading= false
            console.log(error);
  
          }
        }
  
      },
  
      async fetchSessions() {
        try {
          const res = await axios.get('http://localhost:5000/fetch-session');
          if (res.data.code === 's200') {
            res.data.message.forEach(session => {
              this.sessions.push({
                text: `${session.academicYear}_${Number(session.academicYear )+ 1}`,
                value: session.academicYear
              })
            });
  
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      back() {
        this.degree = '';
        this.course = '';
        this.goodData = [];
        this.badData = [];
        this.showform = true;
      },
      async fetchCourses() {
      try {
        var url = this.$appUrl+'/get-courses'
        const res = await axios.get(url);
        // console.log('Course', res);
        if (res.data.code == 's200') {
          res.data.message.filter(course => {
return course.offeringDepartment 
          })
          res.data.message.forEach(course => {
            if(this.$facultyId == course.offeringFaculty){
            this.courses.push({
              text: `${course.name} (${course.course_code})`,
              value: {
                id: course._id,
                department: course.offeringDepartment,
                name: course.name,
                code:course.course_code
              }
              
            })
          }
          });
          // this.courseHolder.push(...this.courses);
          // console.log('Profiled Course', res);
        }
      } catch (error) {
        console.log(error);
      }
    },
      async publish() {
        if (!this.otp) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops..',
            text: 'Please enter otp'
          })
        } else {
          try {
            var url = this.$appUrl+`hod-result-approval`
            const res = await axios.post(url, {
              resultIds: this.payload.map(el => el.id),
              otp: this.otp,
              adminType: 'dean',
            });
  
  
            if (res.data.code == 's200') {
              this.payload.map(el => el.id).forEach(el => {
                const resst = this.goodData.filter(d => d.id == el);
                console.log(resst);
                resst[0].status = false;
                resst[0].stat = '3';
              });
  
              this.otp = '';
              this.showOtp = false;
              this.dialogRegsetT = false;
              this.dialogRegset = false;
  
  
            Swal.fire({
              icon: 'success',
              text: `${this.selected.length} results successfully published`,
              title: 'Result Approval'
            });
            this.selected = [];
  
            } else {
              Swal.fire({
                icon: 'error',
                text: res.data.message,
                title: 'Oops..'
              });
            }
  
          } catch (error) {
            console.log(error);
          }
        }
      },
  
      async reject() {
        if (!this.otp) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops..',
            text: 'Please enter otp'
          })
        } else {
          console.log('here');
          try {
            var url = this.$appUrl+`reject-result`

            const res = await axios.post(url, {
              resultIds: this.payload.map(el => el.id),
              otp: this.otp,
  
              adminType: 'dean',
              reason: this.reason,
              uploadedBy: 'kalchuka@gmail.com'
            });
  
            console.log('REESS',res)
  
            if (res.data.code == 's200') {
              this.showOtp = false;
              this.otp = '';
              this.reason = '';
  
              this.payload.forEach(el => {
                this.goodData.splice(this.goodData.indexOf(el), 1);
              })
  
              this.selected = [];
                this.payload = [];
  
              Swal.fire({
                icon: 'success',
                text: 'Result rejection was successful',
                title: 'Successful'
              });
  
            } else {
              Swal.fire({
                icon: 'error',
                text: res.data.message,
                title: 'Oops..'
              });
            }
  
          } catch (error) {
            console.log(error);
          }
        }
  
      },
  
      async fetchDegree() {
      try {
        const programDegree = await degree().then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          console.log(programDegree);
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
    this.loaded = true

        // }
      } catch (error) {
        console.log(error);
      }

    },
  
    }
  }
  </script>
  
  <style>
  .input-f {
    width: 60px !important;
    display: inline-block;
    background-color: #ccc;
    padding: .4rem;
    border-radius: 3px;
  
  }
  
  .btn-publish {
    font-weight: bolder;
    color: green;
    transition: all ease-in-out .2s;
  }
  
  .btn-publish:active {
    transform: scale(0.9);
  }
  </style>
  
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import { fetchactiveSets,allDepartments, getCookie,menusAsigned,parseJwt} from '@Appmart/utility';
import uploadResult from "../components/resultManager/resultUpload.vue";
import hodModerate from "../components/resultManager/hod.vue";
import deanModerate from "../components/resultManager/Dean.vue";

Vue.use(VueRouter)




const routes = [
  {
    path: '/academics/uploadResult',
    name: 'uploadResult',
  
    component: uploadResult,
    meta:{
requiresAuth:true,check_link:true
    }
  },  
  {
    path: '/academics/hodmode',
    name: 'HODModerate',
  
    component: hodModerate,
    meta:{
requiresAuth:true,check_link:true
    }
  },
  {
    path: '/academics/deanmode',
    name: 'DEANModerate',
  
    component: deanModerate,
    meta:{
requiresAuth:true,check_link:true
    }
  }

  
//   {
//     path: '/gs/registrationreport',
//     name: 'Home',
  
//     component: registratioReport,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   },
//   {
//     path: '/gs/books',
//     name: 'listBooks',
  
//     component: listBooks,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   }, 
//   {
//     path: '/gs/reglist',
//     name: 'registration List',
  
//     component: reglist,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   }, 
//   {
//     path: '/gs/issuebook',
//     name: 'issueBooks',
  
//     component: asignGsBooks,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   },

//   {
//     path: '/gs/bookinventory/:id',
//     name: 'bookBatch',
//     component: bookBatch,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   },
//   {
//     path: '/gs/student-with-books/:id',
//     name: 'studentBatch',
//     component: studentBatchList,
//     meta:{
// requiresAuth:true,check_link:true
//     },
//   },
  
]



const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})



  router.beforeEach(async(to, from, next) => {
    next({ })

////// this before each will have to check if the user is a lecturer or a hod or Dean before allowing to pass

//     if(to.matched.some(record => record.meta.requiresAuth)) {
//     console.log(to.fullPath);  
//     /**
//      * before we can enter any route on this module, we will have to get the active session as well as get the menus for this operation
//      */
//      let name = getCookie("-X_Zka");
//      const tokendecoded = parseJwt(name);
  
//      const activeSetting = await fetchactiveSets().then(activeSet => {
//       return activeSet;
//     }).catch(e => {
//       console.log(e);
//       alert('Something went wrong')
//       next(false)
//     })
//     const getmyMenus = await menusAsigned('userExec',activeSetting.data.data.program._id,tokendecoded.staffid).then(activeSet => {
//       return activeSet;
//     }).catch(e => {
//       console.log(e);
//       alert('Something went wrong')
//       next(false)
//     })
  
//     console.log('found',getmyMenus.data.message.deptAssigned);
//     const fixRoute = to.fullPath.split('/')
//     console.log('full path',to.fullPath.split('/'));

//     const menuPath = '/'+fixRoute[1]+'/'+fixRoute[2];
  
//     const checkifMenuIsBuilt = await getmyMenus.data.message.deptAssigned.find(menuId =>menuId.menuLink ==menuPath);

//   //const checkifMenuIsBuilt = true;
//     console.log('found_menu',checkifMenuIsBuilt);
//   if(checkifMenuIsBuilt){
//     to.params.activeSetting = activeSetting;
//     to.params.getmyMenus = checkifMenuIsBuilt;
//     next({ params: to.params })
//  }else{
//       window.location.href =`${window.location.origin}/#/auth`
//     return
//   }
//     }








    ///////////// commented out before




//   console.log(to);  
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     let name = getCookie("-X_Zka");
//     console.log(name);
//     if(!name){
//   window.location.href =`${window.location.origin}/#/auth3`;
// }else{
//   if(to.matched.some(record1 => record1.meta.check_link)) {
//     const jwtDecode = parseJwt(name)
//     // check if user is eligible for the menu
//   var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//    axios.post(url,{
//     staffid:jwtDecode.staffid,
//     menuPath:to.fullPath
//    })
//                 .then(response => {
//                   if(response.data.code =='00'){
//                     console.log(response.data.message);
//                     //if(response.data.message == true){
//                       next()
//                    // }else{
//                      // window.location.href =`${window.location.origin}/#/auth1`;
//                    // }
//                   }else{
//                     window.location.href =`${window.location.origin}/#/auth2`;
                    
//                   }
//                 })
//                 .catch(e => {
//                   console.log(e);
//                   //window.location.href =`${window.location.origin}/#/auth`;
//                   //alert('Something went wrong')
//                 })  
//   }else{
//     next()
//   }
// }
//   }else{
//     if(to.matched.some(record => record.meta.check_link)) {

//       const jwtDecode = parseJwt(name)
//       // check if user is eligible for the menu
//     var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//      axios.post(url,{
//       staffid:jwtDecode.staffid,
//       menuPath:to.fullPath
//      })
//                   .then(response => {
//                     if(response.data.code =='00'){
//                       console.log(response.data.message);
//                       if(response.data.message == true){
//                         next()
//                       }else{
//                         window.location.href =`${window.location.origin}/#/auth4`;
//                       }
//                     }else{
//                       window.location.href =`${window.location.origin}/#/auth5`;
                      
//                     }
//                   })
//                   .catch(e => {
//                     window.location.href =`${window.location.origin}/#/auth6`;
//                     alert('Something went wrong')
//                   })    }else{
//     next()
//     }
//   }
//   next()
})

export default router

